<template>
  <vx-card class="bank-menu-page main-box md:w-1/2 top-zero-radius"
           v-if="bank && Object.keys(bank).length > 0">

    <profile-main-box :profile="getBankProfile"
                      :actions-list="actionsList"
                      @transactionsTreasuryBank="showBankTransactionsPromptStatus = true"/>

    <!--  show Bank turnover prompt  -->
    <vs-prompt
      class="very-big-prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showBankTransactionsPromptStatus"
      @close="showBankTransactionsPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">

          </vs-col>

          <vs-spacer/>

          <vs-col class="prompt-title w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.banks.transactions.dynamicTitle', {bank: bank.name}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer flex">
<!--            <div class="mx-2" @click="handleClick('printBankTransactions')">-->
<!--              <custom-icon icon="PRINT"/>-->
<!--            </div>-->

            <div @click="showBankTransactionsPromptStatus = false">
              <custom-icon color="danger"
                           icon="TIMES-CIRCLE"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content py-2 px-3">
        <template>
          <keep-alive>
            <bank-transactions :bank-id="bankId"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>
  </vx-card>
</template>

<script>
import ProfileMainBox from '@/components/profileMainBox/profileMainBox'
import {getBank} from '@/http/requests/banks'
import BankTransactions from "../transactions/bankTransactions";
import CustomIcon from "../../../../../../components/customIcon/customIcon";

export default {
  name: 'bankMenu',
  components: {CustomIcon, BankTransactions, ProfileMainBox},
  metaInfo () {
    return {
      title: this.bank ? this.$t('treasury.banks.bank.dynamicTitle', {bank: this.bank.name}) : this.$t('treasury.banks.bank.title')
    }
  },
  props: {
    bankId: {
      type: Number,
      default: () => { return 0 }
    }
  },
  data () {
    return {
      bank: null,
      showBankTransactionsPromptStatus: false,
      actionsList: [
        {
          event: 'transactionsTreasuryBank',
          i18n: 'treasury.banks.bank.actions.transactions',
          icon: 'icon-layers',
          iconPack: 'feather'
        }
      ],
      actions: {
        toolbar: [
          {
            id: {name: 'editTreasuryBank'},
            type: 'link',
            icon: 'icon-edit-2',
            iconPack: 'feather',
            color: 'warning',
            permission: 'bank.update'
          }
        ],
        leftToolbar: [
          {
            id: {name: 'treasuryBanks'},
            type: 'link',
            icon: 'icon-chevron-left',
            iconPack: 'feather'
          }
        ]
      }
    }
  },
  computed: {
    getBankProfile () {
      const bank = {
        avatar: this.bank.avatar ? this.bank.avatar : 'https://4photoshop.ir/wp-content/uploads/4photoshop-Bank-Pasargad-vector-logo-%D9%84%D9%88%DA%AF%D9%88-%D8%A8%D8%A7%D9%86%DA%A9-%D9%BE%D8%A7%D8%B3%D8%A7%D8%B1%DA%AF%D8%A7%D8%AF.jpg',
        name: this.bank.name,
        details: [],
        importantData: [
          {
            key: this.$t('treasury.banks.bank.importantData.balance'),
            value: this.bank.balance ? Math.abs(this.bank.balance) : 0,
            class: this.bank.balance < 0 ? 'text-danger' : 'text-success',
            type: 'price'
          },
          {
            key: this.$t('treasury.banks.bank.importantData.openingBalance'),
            value: this.bank.opening_inventory ? this.bank.opening_inventory : 0,
            class: 'text-success',
            type: 'price'
          },
          {
            key: this.$t('treasury.banks.bank.importantData.transactionsCount'),
            value: this.bank.transaction_count || 0,
            class: 'text-primary'
          }
        ]
      }

      if (this.bank.card_number) bank.details.push({
        key: this.$t('treasury.banks.labels.cardNumber'),
        icon: 'icon-credit-card',
        iconPack: 'feather',
        value: this.bank.card_number.toString().replace(/\B(?=(\d{4})+(?!\d))/g, '-'),
        style: 'direction: rtl; display: inherit;'
      })

      if (this.bank.account_number) bank.details.push({
        key: this.$t('treasury.banks.labels.accountNumber'),
        icon: 'icon-hash',
        iconPack: 'feather',
        value: this.bank.account_number.toString()
      })

      if (this.bank.shaba_number) bank.details.push({
        key: this.$t('treasury.banks.labels.shebaNumber'),
        icon: 'icon-hash',
        iconPack: 'feather',
        value: `IR${  this.bank.shaba_number.toString()}`
      })

      return bank
    }
  },
  created () {
    if (this.bankId === 0) {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'md:w-1/2 mx-auto')
      }, 50)
    }

    this.getBank()
  },
  methods: {
    getBank () {

      getBank(this.bankId || this.$route.params.id).then(response => {
        this.bank = response.data.data
      })
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  }
}
</script>

<style lang="scss">
.bank-menu-page {

  .vx-card__body {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}
</style>
